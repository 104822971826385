import FormInput from '@/common/components/FormInput/FormInput'
import { StyledButton } from '@/common/components/StyledButton/StyledButton'
import useLogin from '@/common/hooks/useLogin'
import PageLayout from '@/layouts/PageLayout'
import { Box, CircularProgress, styled, Typography } from '@mui/material'
import { FormProvider } from 'react-hook-form'

export default function Login() {
  const { methods, handleLogin, loadingLogin } = useLogin()

  return (
    <PageLayout gradient={<Gradient />} showSignButton={false}>
      <Box width="100%" display="flex" justifyContent="center" mt={'20%'}>
        <Box
          sx={{
            backgroundColor: '#FFFFFF1F',
            width: '380px',
            padding: '20px',
            borderRadius: '12px',
            gap: '20px',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <FormProvider {...methods}>
            <form onSubmit={handleLogin}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '10px',
                }}
              >
                <Typography fontSize="22px" fontWeight={600} color="#FFFFFF">
                  Login
                </Typography>
                <FormInput name="signInEmail" label="Email" />
                <StyledButton type="submit">
                  Sign in{' '}
                  {loadingLogin && (
                    <CircularProgress
                      sx={{
                        width: '15px !important',
                        height: '15px !important',
                        color: '#000',
                      }}
                    />
                  )}
                </StyledButton>
              </Box>
            </form>
          </FormProvider>
        </Box>
      </Box>
    </PageLayout>
  )
}

const Gradient = styled('div')(() => ({
  background:
    'radial-gradient(50% 64.61% at 50% 0%, #331016 0%, #1C051A 37.2%, #03090F 69.6%, #000000 100%)',
  position: 'absolute',
  width: '100%',
  height: '420px',
  left: '0',
  right: '0',
  margin: '0 auto',
  top: '0px',
  zIndex: 0,
}))
